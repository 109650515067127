import { useState, useEffect } from "react";

import "./OptOut.css";

function OptOut() {
  useEffect(() => {
    document.title = "Cyberslotbox | OptOut";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  // const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');

  const fetchData = async (dataObj) => {
    try {
      setIsLoading(true);
      const response = await fetchFormData('/api/optout.php', dataObj);
      if (typeof response.status !== "undefined" && response.status != null && response.status === "success") {
        setIsLoading(false);
        setShowMessage(true);
        setMessageText(response.message);
        setName('');
        setEmail('');
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setMessageText(response.message);
        setTimeout(() => {
          setMessageText('');
          setName('');
          setEmail('');
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setMessageText('Sorry, please try again later...');
      setTimeout(() => {
        setMessageText('');
        setName('');
        setEmail('');
      }, 3000);
      console.error('Error:', error);
    }
  };


  let isFormValid = false;
  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = 'Name is required.';
    }

    if (!email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid.';
    }

    setErrors(errors);
    // setIsFormValid(Object.keys(errors).length === 0);
    isFormValid = Object.keys(errors).length === 0;
  };
  // Submit
  const handleSubmit = async () => {
    validateForm();

    if (isFormValid) {
      const data = {name: name, email: email, received: new Date().toJSON().slice(0, 10)}
      await fetchData(data);
    }
  };

  function fetchFormData(url, param) {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
      body: new URLSearchParams(param)
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  return (
    <div className="text-page-body contact-bg">
      <div className="opt-cover">
        <h3 className="opt-h3">Opt-Out Instructions:</h3>
        <p className="opt-p">
          If you wish to opt-out of our use of your personal information, particularly for marketing purposes, please contact us using the form provided on our website.
        </p>
        <h3 className="opt-h3">Compliance with Legal Obligations:</h3>
        <p className="opt-p">Cyberslotbox complies with all applicable laws and regulations regarding data protection and privacy.</p>
      </div>
      <div className="contact-cover">
        <div className="contact-form-cover">
          <>
            {isLoading && <p>Loading...</p>}
            <form>
              <label htmlFor="name">
                <input value={name}
                       onChange={(e) => setName(e.target.value)}
                       type="text"
                       id="name-c"
                       placeholder="Name"
                />
                {errors.name && <div className="error_message">{errors.name}</div>}
              </label>

              <label htmlFor="email-c">
                <input value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       type="email"
                       id="email-c"
                       placeholder="Email"
                />
                {errors.email && <div className="error_message">{errors.email}</div>}
              </label>
              <span className="main-btn"
                  // style={{opacity: isFormValid ? 1 : 0.5 }}
                  // disabled={!isFormValid}
                    onClick={handleSubmit} >
                    Submit
                  </span>
            </form>
            {showMessage && <div className="show-success-message">{messageText}</div>}
          </>

          {/*<div className={showForm ? "hide-success-message" : "show-success-message"}>Thank you for your message! We will contact you soon</div>*/}
        </div>
        <div className="contact-picture">
          <picture>
            <source srcSet="/assets/images/transformer.webp" type="image/webp" />
            <img src="/assets/images/transformer.png" alt="Cyberslotbox" className="about-image" />
          </picture>
        </div>
      </div>
    </div>
  );
}

export default OptOut;
