import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SignUpPopup from "../components/SignUpPopup";
import ThankYouPopup from "../components/ThankYouPopup";

import "./Top10.css";

function Top10({ countryData }) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [contentHeight, setContentHeight] = useState();
  // const [showRegistrationBtn, setShowRegistrationBtn] = useState(true);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [showSClickId, setShowSClickId] = useState(false);
  const [sClickIdFrameSrc, setSClickIdFrameSrc] = useState("");
  const gameLinkArr = [12, 2, 6, 3, 5];
  const smallGameLinkArr = [0, 1, 8, 9, 13];

  const search = useLocation().search;
  const sClickId = new URLSearchParams(search).get("_sclickid");

  const b64_to_utf8 = (base64String) => {
    let atob = require("atob");
    try {
      var bin = atob(base64String);
      return bin;
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    document.title = "Cyberslotbox.com - Top 10";

    isLooged();

    const renderSClickFrame = () => {
      let frameSrc = b64_to_utf8(sClickId);
      setShowSClickId(true);
      setSClickIdFrameSrc(frameSrc);
    };

    if (sClickId && sClickId.length > 1) {
      renderSClickFrame();
    }
  }, [setCanPlay, sClickId]);

  useEffect(() => {
    const contentBlock = document.querySelector(".top-page-body");
    setContentHeight(contentBlock.clientHeight - 350);
  }, [setContentHeight]);

  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if (result.is_auth === true) {
          // setShowRegistrationBtn(false);
          setCanPlay(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openSignUpPopup = () => {
    setShowSignUpPopup(true);
  };
  const closeRegisterPopup = () => {
    setShowSignUpPopup(false);
  };
  const userRegistered = () => {
    closeRegisterPopup();
    setShowThankYouPopup(true);
  };
  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/";
  };
  const closeThanksPopup = () => {
    setShowThankYouPopup(false);
    console.log(pathname);
    if (pathname === "/it/" || pathname === "/it") {
      window.location.href = "/it/";
    } else {
      window.location.href = "/";
    }
  };

  return (
    <div className="top-page-body" style={{ backgroundImage: `url('/assets/images/top10-bg.png')`, minHeight: `${contentHeight}px` }}>
      <React.Fragment>
        {showSignUpPopup ? <SignUpPopup closeRegisterPopup={closeRegisterPopup} userLogged={userLogged} userRegistered={userRegistered} countryData={countryData} /> : null}
      </React.Fragment>

      <React.Fragment>{showThankYouPopup ? <ThankYouPopup closeThanksPopup={closeThanksPopup} /> : null}</React.Fragment>

      <div className="container">
        <h1 className="top-games-title">Top 10 free online games</h1>
        <p className="top-games-description">
          Our fine collection of games is created with utmost care for the user's satisfaction. You can't go wrong with anything in the list below. Get ready for the vibrating
          reality with Cyberslotbox!
        </p>
        <div className="top-games-cover">
          <div className="top-games-container">
            <div className="top-games-top-block">
              {[...Array(5).keys()].map((num) => (
                <div className="top-games-image-block" key={num}>
                  <img width="220" height="300" src={`/assets/images/game-${num + 1}.jpg`} alt="game" />
                  <div className="btn-block">
                    <button
                      className="all-games-signup all-games-signup-btn-sm"
                      onClick={
                        !canPlay
                          ? openSignUpPopup
                          : () => {
                              navigate(`/play/${gameLinkArr[num]}`);
                            }
                      }
                    >
                      Play Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="top-games-bottom-block">
              {[...Array(5).keys()].map((num) => (
                <div className="top-games-image-block" key={num}>
                  <img width="220" height="220" src={`/assets/images/game-${num + 6}.png`} alt="game" />
                  <div className="btn-block">
                    <button
                      className="all-games-signup all-games-signup-btn-sm"
                      onClick={
                        !canPlay
                          ? openSignUpPopup
                          : () => {
                              navigate(`/play/${smallGameLinkArr[num]}`);
                            }
                      }
                    >
                      Play Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>{showSClickId ? <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe> : null}</React.Fragment>
    </div>
  );
}

export default Top10;
