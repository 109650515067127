import React, { useEffect, useState } from "react";
import "./Userspage.css";

function Userspage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/users/users.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const fetchedData = await response.json();
        setData(fetchedData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // console.error('Error:', error);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    document.title = "Cyberslotbox - Userspage";
  }, []);

  return (
      <div className="text-page-body__userspage">

      <div className="container">
          <div className="main-banner-content-cover">
            <>
              {isLoading && <p>Loading...</p>}

              {!data.length ? (
                  <h3>Sorry, the list is empty. Please try again later.</h3>
              ) : (
                  data.map(({ name, email, received }) => (
                      <ul className="user-item" key={Math.random()}>
                        <li><b>Name</b>: {name}</li>
                        <li><b>Email</b>: {email}</li>
                        <li><b>Received</b>: {received}</li>
                      </ul>
                  ))
              )}
            </>
          </div>
        </div>
      </div>

      // <div className="text-page-body users__contact-body opt-out-cover" style={{ minHeight: `${contentHeight}px` }}>
    //   <div className="opt-out">
    //     <h3>List of users from Opt-Out page:</h3>
    //   </div>
    //   <div className="users__contact-mobile-container" style={{ minHeight: "60vh" }}>
    //     <div className="users__contact-form-cover">
    //       <div className="container">
    //         <div className="optout-form">
    //           <>
    //             {isLoading && <p>Loading...</p>}
    //
    //             {!data.length ? (
    //                 <h3>Sorry, the list is empty. Please try again later.</h3>
    //             ) : (
    //                 data.map(({ name, email, received }) => (
    //                     <ul className="user-item" key={Math.random()}>
    //                       <li>Name: {name}</li>
    //                       <li>Email: {email}</li>
    //                       <li>Received: {received}</li>
    //                     </ul>
    //                 ))
    //             )}
    //           </>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
}

export default Userspage;
