import { useEffect, useState } from "react";
import "./PageNotFound.css";

function PageNotFound() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Cyberslotbox | 404 page not found";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 381);
  }, [setContentHeight]);

  return (
    <div className="text-page-body notfound" style={{ minHeight: `${contentHeight}px` }}>
      <div className="page404">
        <img src="/assets/images/404.webp" alt="about" className="about-image" />
      </div>
    </div>
  );
}

export default PageNotFound;
