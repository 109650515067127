import { useEffect, useState } from "react";
import "./Terms.css";

function Terms() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Cyberslotbox | Terms & Conditions";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
    <div className="text-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover legals">
        <h1>Terms & Conditions</h1>
        <p>
          Welcome to www.cyberslotbox.com ("CyberSlotBox", "the website"). We hope that you will read these Terms and Conditions carefully. By accessing and using the website, it
          means that you understand and agree to be bound and subject to all the regulations that apply on this website. Please do not access this website if you disagree to be
          bound by all applicable regulations.
        </p>
        <p>
          <strong>Changes to Terms and Condition</strong>
        </p>
        <p>
          We can change, replace, add or reduce these Terms and Conditions at any time. Therefore, you are bound by any such changes and should periodically check this page to
          check the Terms and Conditions.
        </p>

        <p>
          <strong>Disclaimer</strong>
        </p>
        <p>
          <strong>
            CyberSlotBox provides totally free games with no real-money winnings and social entertainment platform related information for adult audience entertainment (visitors
            under 18 years old are restricted). We do not offer any form of online gaming and remind users that success in our games does not guarantee future success at "real
            money" gaming.{" "}
          </strong>
        </p>

        <p>
          <strong>Responsible gaming</strong>
        </p>
        <p>
          Even though gaming is an enjoyable activity for many people, this activity may become a problem. CyberSlotBox promotes the use of "responsible gaming" and reminds users
          that playing free social games, and success playing these games, should not be considered the same as a success in "real money" entertainment games. If you, or someone
          you care about, developed a compulsive behavior towards gaming, seek professional help.
        </p>

        <p>
          <strong>Registered Visitors</strong>
        </p>
        <p>
          Some services on this website are only provided for registered visitors, and visitors must register by providing the correct data complete with a user ID to use it. As a
          registered visitor, you must follow all the rules for visiting the service.
        </p>

        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>
          The website's privacy policy discloses the policy for handling your personal data when you access the website. Regular and continuous use of the website indicates your
          agreement with our Privacy Policy.
        </p>

        <p>
          <strong>Right Of Ownership</strong>
        </p>
        <p>
          Our copyrights are all designs, images, artwork, audio, video, and programming code (hereinafter referred to as 'content') on the www.cyberslotbox.com website. You cannot
          modify, copy, change or add to the design designs, images, artwork, audio, video, and programming code in this facility under any circumstances or conditions.
        </p>

        <p>
          <strong>Users' License </strong>
        </p>
        <p>
          You are allowed to use the website and the content offered by the website only for personal purposes, not for commercial purposes. You can use the content that is allowed
          to be uploaded, such as photos and videos, for personal purposes and according to the rules of the content in question. You are not allowed to reproduce, print, copy,
          store, publish, display, distribute, modify, translate, publish, transfer, sell, lend or distribute the content of the website without our written permission.
        </p>

        <p>
          <strong>Other website Links</strong>
        </p>
        <p>
          CyberSlotBox may contain links to various third-party websites. These links do not indicate that we approve of the third party website. User acknowledges and agrees that
          CyberSlotBox is not responsible for the content or other materials on such third-party websites. User acknowledges and agrees that CyberSlotBox is not liable for any loss
          or claim caused by the advertiser.
        </p>
      </div>
    </div>
  );
}

export default Terms;
