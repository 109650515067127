import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import SignUpPopup from "../components/SignUpPopup";
import ThankYouPopup from "../components/ThankYouPopup";

import "./Lp.css";

function LP({ countryData }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [canPlay, setCanPlay] = useState(false);
  const [cookies, setCookie] = useCookies(["is18"]);

  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [showSClickId, setShowSClickId] = useState(false);
  const [sClickIdFrameSrc, setSClickIdFrameSrc] = useState("");

  const search = useLocation().search;
  const sClickId = new URLSearchParams(search).get("_sclickid");

  const b64_to_utf8 = (base64String) => {
    let atob = require("atob");
    try {
      var bin = atob(base64String);
      return bin;
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    document.title = "Cyberslotbox";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    isLooged();

    const renderSClickFrame = () => {
      let frameSrc = b64_to_utf8(sClickId);
      setShowSClickId(true);
      setSClickIdFrameSrc(frameSrc);
    };

    if (sClickId && sClickId.length > 1) {
      renderSClickFrame();
    }
  }, [setCanPlay, cookies.is18, setCookie, sClickId]);

  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if (result.is_auth === true) {
          setCanPlay(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openSignUpPopup = () => {
    setShowSignUpPopup(true);
  };

  const closeRegisterPopup = () => {
    setShowSignUpPopup(false);
  };

  const userRegistered = () => {
    closeRegisterPopup();
    setShowThankYouPopup(true);
  };

  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/it/";
  };

  const closeThanksPopup = () => {
    setShowThankYouPopup(false);
    console.log(location.pathname);
    if (location.pathname === "/it/" || location.pathname === "/it") {
      window.location.href = "/it/";
    } else {
      window.location.href = "/";
    }
  };
  const steps = [
    { imageUrl: "step-1", name: "register", description: "Simply sign up to Cyberslotbox and get access to an extensive selection of free games.", id: 1 },
    { imageUrl: "step-2", name: "start playing", description: "You can play both with your mobile device or desktop, so just click on your chosen game to start playing.", id: 2 },
    { imageUrl: "step-3", name: "have fun", description: "Be a part of our community. Receive exclusive updates on new games and features via email or phone.", id: 3 },
  ];
  return (
    // <div className="lp-home-cover" style={ window.innerWidth > 700 ?{ backgroundImage: `url(/assets/lp/lp-banner.webp` } : null}>
    <div className="lp-home-cover">
      <React.Fragment>
        {showSignUpPopup ? <SignUpPopup closeRegisterPopup={closeRegisterPopup} userLogged={userLogged} userRegistered={userRegistered} countryData={countryData} /> : null}
      </React.Fragment>

      <React.Fragment>{showThankYouPopup ? <ThankYouPopup closeThanksPopup={closeThanksPopup} /> : null}</React.Fragment>

      <section className="lp-banner" style={window.innerWidth > 850 ? { backgroundImage: `url(/assets/lp/main-banner.webp` } : null}>
        <div className="lp-content-cover">
          <div className="steps-cover">
            {steps.map(({ imageUrl, name, description, id }) => (
              <div className="step-block" style={window.innerWidth > 850 ? { backgroundImage: `url(/assets/lp/${imageUrl}.png` } : null} key={id}>
                <div className="step-text">
                  <h3>{name}</h3>
                  <p>{description}</p>
                </div>
                {window.innerWidth < 850 ? <img src={`/assets/lp/${imageUrl}-icon.png`} className="step-icon" alt="Cyberslotbox" /> : null}
              </div>
            ))}
          </div>
          <React.Fragment>
            {!canPlay ? (
              <button className="lp-play-now" onClick={openSignUpPopup}>
                Play now
              </button>
            ) : (
              <Link className="lp-play-now" to="/play/0">
                Play now
              </Link>
            )}
          </React.Fragment>
        </div>
        {window.innerWidth < 850 ? <img src="/assets/lp/bottom-circle.svg" className="step-img" alt="Cyberslotbox" /> : null}
      </section>

      <div className="lp-content-cover games">
        <h1>TOP 10 ONLINE GAMES</h1>
        <div className="lp-games-section">
          {[...Array(12).keys()].map((num) => (
            <div className="lp-game-cover" key={num}>
              <div className="lp-game">
                <img className="desktop" src={`/assets/lp/lp-game-${num + 1}.svg`} alt="" />
                <React.Fragment>
                  {!canPlay ? (
                    <button className="open-game" onClick={openSignUpPopup}>
                      Play Now
                    </button>
                  ) : (
                    <Link className="open-game" to={`/play/${num}`}>
                      Play now
                    </Link>
                  )}
                </React.Fragment>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="super-game-cover" style={window.innerWidth > 850 ? { backgroundImage: `url(/assets/lp/super-game-bg.webp` } : null}>
        <h1>Super game</h1>
        {window.innerWidth < 850 ? <img src="/assets/lp/statue.png" className="img top" alt="Cyberslotbox" /> : null}
        <div>
          <div className="super-game">
            <div className="image-block">
              <img src="/assets/lp/super-game.svg" className="super-game-img" alt="Cyberslotbox" />
            </div>
            <React.Fragment>
              {!canPlay ? (
                <button className="signup-btn" onClick={openSignUpPopup}>
                  Play now
                </button>
              ) : (
                <Link className="signup-btn" to="/play/12">
                  Play now
                </Link>
              )}
            </React.Fragment>
            <div className="text-block">
              <p>
                There are three characters with their own skills and abilities to select. Afterward, you can change him to a new player on the go. You will battle against Tritons -
                mutated insects. Fire your way through the mutants and then try to defeat their Queen.
              </p>
            </div>
          </div>
        </div>
        {window.innerWidth < 850 ? <img src="/assets/lp/micro.png" className="img" alt="Cyberslotbox" /> : null}
      </div>

      <React.Fragment>{showSClickId ? <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe> : null}</React.Fragment>
    </div>
  );
}

export default LP;
