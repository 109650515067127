import { useEffect, useState } from "react";
import "./About.css";

function About() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Cyberslotbox | About";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 500);
  }, [setContentHeight]);

  return (
    <div className="text-page-body about" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover">
        <h1>Let’s have some fun</h1>
        <p>
          We all love to have fun. That’s why we created the Cyberslotbox casino, featuring the most exciting games on the net. But with one pleasant difference from many other
          venues: we don’t charge you money for playing. Here you are in a social casino created for your entertainment only. So you can try whatever you like in Cyberslotbox free
          of charge.
        </p>
        <p>
          We ourselves like that feeling of hitting the jackpot. Now you can experience it by yourself without spending the money. For our team, you, the player, are of significant
          value. That’s why we created a casino ambient that is nice and convenient for our visitors, to fit you like a glove.
        </p>
      </div>
      <img src="/assets/images/about-bg.webp" alt="about" className="about-image" />
    </div>
  );
}

export default About;
