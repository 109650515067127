import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import SignUpPopup from "../components/SignUpPopup";
import ThankYouPopup from "../components/ThankYouPopup";
import { useCookies } from "react-cookie";
import "./Header.css";

function Header({ countryData }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let [mobMenu, setMobMenu] = useState(false);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [cookies, setCookie] = useCookies(["is18"]);
  const [isAgeTerms, setIsAgeTerms] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [loginPopupStatus, setLoginPopupStatus] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [show18popupBg, setShow18popupBg] = useState(true);
  const [docWidth, setDocWidth] = useState(false);
  const [isLP, setIsLP] = useState(false);
  const [is404, setIs404] = useState(false);
  const [isCookie, setIsCookie] = useState(false);

  useEffect(() => {
    if (cookies.is18 === "false" && pathname === "/cookies/") {
      setShowAgepopup(false);
      setIsCookie(true);
    } else if (cookies.is18 === "true") {
      setShowAgepopup(false);
      setIsCookie(false);
    } else {
      setShowAgepopup(true);
      setIsCookie(false);
    }

    if (pathname === "/it/" || pathname === "/it") {
      setIsLP(true);
    } else {
      setIsLP(false);
    }

    if (pathname === "/page404/" || pathname === "/page404") {
      setIs404(true);
    } else {
      setIs404(false);
    }

    setDocWidth(document.body.clientWidth);

    isLooged();
  }, [cookies.is18, pathname]);

  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if (result.is_auth === true) {
          setShowRegisterBtn(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const logOut = async () => {
    await fetch(`/api/user/logout.php`)
      .then((response) => response.json())
      .then((result) => {
        // window.location.href = "/";
        navigate(0);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const toggleMobMenu = () => {
    setMobMenu(!mobMenu);
    console.clear();
  };

  const openSignUpPopup = () => {
    setShowRegisterPopup(true);
    setLoginPopupStatus(false);
    setMobMenu(false);
  };

  const openLoginPopup = () => {
    setShowRegisterPopup(true);
    setLoginPopupStatus(true);
    setMobMenu(false);
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
    setLoginPopupStatus(false);
  };

  const userRegistered = () => {
    closeRegisterPopup();
    setShowThanksPopup(true);
  };

  const userLogged = () => {
    closeRegisterPopup();
    // window.location.href = "/";
    window.location.reload();
  };

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    if (pathname === "/it/" || pathname === "/it") {
      window.location.href = "/it/";
    } else if (pathname === "/top10/" || pathname === "/top10") {
      window.location.href = "/top10/";
    } else {
      // window.location.href = "/";
      navigate(0);
    }
  };

  const checkAgeTerms = () => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if (termsChecked !== "not set") {
      setIsAgeTerms(true);
    } else {
      setIsAgeTerms(false);
    }
  };

  const hideAgePopup = (e) => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if (isAgeTerms && termsChecked === "yes") {
      // if (isAgeTerms) {
      setCookie("is18", true, { path: "/" });
      setShowAgepopup(false);
      // navigate(`/play/${theGame}`);
      setIsAgeTerms(true);
    } else {
      setIsAgeTerms(false);
    }
  };

  const closeAgePopup = () => {
    const popupBody = document.querySelector(".popup-18-body");
    const popupText = document.querySelector(".popup-18-body h2");
    const buttons = document.querySelector(".buttons");
    // const buttonNo = document.getElementById('button-no');
    const checkboxCookies = document.querySelector(".checkbox-cover");

    buttons.style.display = "none";
    checkboxCookies.style.display = "none";
    popupText.style.cssText = `
      font-size: 40px; 
      position: absolute;
      text-align: center;
      top: 35%;
      transform: translateY(-50%);
      line-height: 52px;
    `;
    if (window.innerWidth < 500) {
      popupText.style.cssText = `
      font-size: 28px; 
      line-height: 40px;
      position: absolute;
      text-align: center;
      top: 35%;
      transform: translateY(-50%);
    `;
    }
    popupText.textContent = "Sorry, we can't let you play our games:(";

    setShow18popupBg(!show18popupBg);
  };

  const openCookiePage = () => {
    localStorage.setItem("page", pathname);
  };

  return (
    <div>
      <React.Fragment>
        {showAgePopup ? (
          <div className="show-18-popup">
            <div className="popup-18-overlay"></div>
            <div className="popup-18-body" style={show18popupBg ? { backgroundImage: "" } : { backgroundImage: `url('/assets/images/secure.png')` }}>
              <h2>Over 18 years old?</h2>
              <div className="checkbox-cover">
                <label htmlFor="terms-18">
                  <input type="checkbox" name="terms-18" id="terms-18" value="yes" onChange={checkAgeTerms} />I agree with{" "}
                  <Link target="_blank" to="/cookies/" onClick={openCookiePage}>
                    Cookies Policy
                  </Link>
                  <span className="box checkbox"></span>
                </label>

                <label className="hidden">
                  <input type="checkbox" name="terms-18" value="not set" defaultChecked />
                  <span className="box checkbox"></span>
                </label>

                <div className={!isAgeTerms ? "show-error" : "hide-error"}>This checkbox is required</div>
              </div>

              <div className="buttons">
                <button id="button-yes" onClick={hideAgePopup}>
                  Yes
                </button>
                <button id="button-no" onClick={closeAgePopup}>
                  No
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>

      <header className="header">
        <div className="header-disclaimer">This website is designed to offer free games, no money included</div>
        <div className="header-cover">
          <React.Fragment>
            {!isLP && !isCookie ? (
              <Link to="/">
                <img width="270" height="67" src="/assets/images/logo.svg" className="header-logo" alt="logo" />
              </Link>
            ) : (
              <img width="270" height="67" src="/assets/images/logo.svg" className="header-logo" alt="logo" />
            )}
          </React.Fragment>

          <React.Fragment>
            {!isCookie && !is404 ? (
              !isLP ? (
                <ul className={mobMenu ? "navigation-list show-menu" : "navigation-list"}>
                  <li>
                    <NavLink to="/" onClick={toggleMobMenu}>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about/" onClick={toggleMobMenu}>
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact/" onClick={toggleMobMenu}>
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/disclaimer/" onClick={toggleMobMenu}>
                      Disclaimer
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/opt-out/" onClick={toggleMobMenu}>
                      Opt Out
                    </NavLink>
                  </li>
                  <React.Fragment>
                    {showRegisterBtn && mobMenu && docWidth <= 700 ? (
                      <>
                        <li>
                          <button className="sign-up-button" onClick={openSignUpPopup}>
                            Sign Up
                          </button>
                        </li>
                        <li>
                          <button className="login-btn mobile-menu-login-btn" onClick={openLoginPopup}>
                            Log In
                          </button>
                        </li>
                      </>
                    ) : null}
                  </React.Fragment>
                  <React.Fragment>
                    {!showRegisterBtn && mobMenu && docWidth <= 700 ? (
                      <li>
                        <div className="mobile-menu-login-btn" onClick={logOut}>
                          Log out
                        </div>
                      </li>
                    ) : null}
                  </React.Fragment>
                </ul>
              ) : (
                <ul className={mobMenu ? "navigation-list show-menu" : "navigation-list"}>
                  <React.Fragment>
                    {mobMenu && showRegisterBtn ? (
                      <>
                        <li>
                          <button className="lp-sign-up-button" onClick={openSignUpPopup}>
                            Sign Up
                          </button>
                        </li>
                        <li>
                          <button className="page login-btn" onClick={openLoginPopup}>
                            Log In
                          </button>
                        </li>
                      </>
                    ) : null}
                  </React.Fragment>
                  <React.Fragment>
                    {!showRegisterBtn && mobMenu && docWidth <= 700 ? (
                      <li>
                        <div className="mobile-menu-login-btn" onClick={logOut}>
                          Log out
                        </div>
                      </li>
                    ) : null}
                  </React.Fragment>
                </ul>
              )
            ) : null}
          </React.Fragment>

          <React.Fragment>
            {!isCookie && !is404 ? (
              showRegisterBtn ? (
                <React.Fragment>
                  {docWidth > 700 ? (
                    <div>
                      <button className="login-btn" onClick={openLoginPopup}>
                        Log In
                      </button>
                      <button className="sign-up-button" onClick={openSignUpPopup}>
                        Sign Up
                      </button>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {docWidth > 700 ? (
                    isLP ? (
                      <button className="lp-sign-up-button" onClick={logOut}>
                        Log Out
                      </button>
                    ) : (
                      <button className="sign-up-button" onClick={logOut}>
                        Log Out
                      </button>
                    )
                  ) : null}
                </React.Fragment>
              )
            ) : null}
          </React.Fragment>

          <React.Fragment>
            {!isCookie && !is404 ? (
              <button className="mobile-menu-btn" onClick={toggleMobMenu}>
                <React.Fragment>{mobMenu ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}</React.Fragment>
              </button>
            ) : null}
          </React.Fragment>
        </div>

        <React.Fragment>
          {showRegisterPopup ? (
            <SignUpPopup
              closeRegisterPopup={closeRegisterPopup}
              userLogged={userLogged}
              userRegistered={userRegistered}
              countryData={countryData}
              loginPopupStatus={loginPopupStatus}
            />
          ) : null}
        </React.Fragment>

        <React.Fragment>{showThanksPopup ? <ThankYouPopup closeThanksPopup={closeThanksPopup} /> : null}</React.Fragment>
      </header>
    </div>
  );
}

export default Header;
