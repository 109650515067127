import React from 'react';
import Authentication from "./Authentication";
import Cookies from 'js-cookie';

const PrivateRoute = ({ element }) => {
    const isAuthenticated = Cookies.get('login');

    return (
        <>
            {isAuthenticated ? element : <Authentication /> }
        </>
    );
};
export default PrivateRoute