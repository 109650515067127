import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Cookies.css';

function Cookies() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Cyberslotbox | Cookies Policy';

    const header = document.querySelector('.header');
    const footer = document.querySelector('.footer');

    header.style.display = 'none';
    footer.style.display = 'none';
    
  }, [setContentHeight]);

  return(
    <div className="text-page-body text-page-body-cookies" style={ {minHeight: `${ contentHeight }px`} }>
      <div className="content-cover legals">
        <h1>Cookies Policy</h1>
        <p><strong>What Are Cookies?</strong></p>
        <p>Cookies are small data files placed on computers and other devices to identify and remember users or devices to store data. We may automatically collect information through cookies or other similar technologies when users visit our website. Cookies are categorized according to their function and purpose, i.e., cookies required for use. Functional cookies Cookies to improve productivity and cookies for marketing.</p>

        <p><strong>How We Use Cookies</strong></p>
        <p>We may collect information through cookies for accessibility information. To improve your user experience and for other purposes, including:</p>
        <ul>
          <li>Cookies help us identify you as a registered user on our service or keep the settings or information you have previously given us.</li>
          <li>We can use cookies to understand what users use on our services or which pages and which parts of the service are the most popular.</li>
          <li>Using cookies allows us to deliver advertisements that we believe will interest you based on your information. </li>
        </ul>

        <p>We may allow advertisers and other third parties to place cookies, web beacons, and similar technologies on some of our services to analyze how these services are used to deliver advertisements. Please note that their own privacy policies govern third-party use of cookies. We cannot be responsible for the use of cookies by third parties. The cookies we use are called first-party cookies. The cookies used by our partners or third parties are third-party cookies. Our website does not have access to information collected by third-party cookies and other companies. Also, the data stored through our cookies will not be accessed.</p>

        <p><strong>Types of Cookies We Use</strong></p>
        <ul>
          <li><strong>Strictly necessary cookies.</strong> These cookies inform us when you use our services to provide appropriate and safe services for our users. For example, these cookies help us recognize that you have accessed the membership system and display relevant information.</li>
          <li><strong>Performance cookies.</strong> We use cookies to save patterns. Your preferences to ensure that our services are suitable for you. These cookies allow our website to remember information that will change the display, such as the language you set or the region where you live. If you proceed to delete such information collected by cookies settings may result in an ineffective experience on our website. But this will not hinder your use of our services.</li>
          <li><strong>Security.</strong> We use cookies to enhance your information security and prevent fraud, crime, or other suspicious activities. This cookie can determine whether you are logged into a member of our services in the local area regularly or not and prevent impersonation and other inappropriate information.</li>
          <li><strong>Analytics.</strong> We use this cookie to track and improve our services and find references for the research and development of our new services or systems. Cookies help us better understand how you use our services and help us improve the experience of our users. Such information may include each time you use our services, as well as which pages and systems you use.</li>
          <li><strong>Targeting cookies or advertising cookies.</strong> We use them to improve our advertisements and make them more tailored to your preferences. For example, these cookies allow us to show you more relevant advertisements, track which advertisements you view or view on our services, and access those advertisements by advertisers. Our partners may use this cookie to ensure that we serve advertisements effectively or provide us with information about your interactions and the advertiser.</li>
        </ul>
        <p><strong>Cookies Management</strong></p>
        <p>You can manage cookies on the website through your browser settings. And you have the option to change your settings by accepting, rejecting, or deleting cookies if you choose to change your settings. You may find that our systems or services may not provide the best service. The settings on each browser are quite different. Therefore, to manage cookies, you should check the settings on your browser.</p>

        <p>These cookies are essential and help our website run smoothly. You can disable such cookies through your browser settings. However, you may not be able to use the services of our website as needed.</p>

        
        <Link target="_blank" className="game-back-link" to='/'><i className="fas fa-arrow-left"></i>Back</Link>

      </div>
    </div>
  )
}

export default Cookies;