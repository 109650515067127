import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useCookies } from "react-cookie";
import SignUpPopup from "../components/SignUpPopup";
import ThankYouPopup from "../components/ThankYouPopup";

import "./Home.css";
import "../components/owl-carousel.css";
<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"></script>;

function Home({ countryData }) {
  let navigate = useNavigate();
  const location = useLocation();

  const [canPlay, setCanPlay] = useState(false);
  const [cookies, setCookie] = useCookies(["is18"]);
  // const [choosenGame, setChoosenGame] = useState();
  // const [showSignUpButton, setShowSignUpButton] = useState(true);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [contentHeight, setContentHeight] = useState();
  const [showPicture, setShowPicture] = useState(true);

  useEffect(() => {
    document.title = "Cyberslotbox.com | Home";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    isLooged();

    const contentBlock = document.querySelector("#play-games");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setCanPlay, cookies.is18, setCookie]);

  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if (result.is_auth === true) {
          // setShowSignUpButton(false);
          setCanPlay(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openSignUpPopup = () => {
    setShowSignUpPopup(true);
  };
  const closeRegisterPopup = () => {
    setShowSignUpPopup(false);
  };
  const userRegistered = () => {
    closeRegisterPopup();
    setShowThankYouPopup(true);
  };
  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/";
  };
  const closeThanksPopup = () => {
    setShowThankYouPopup(false);
    console.log(location.pathname);
    if (location.pathname === "/it/" || location.pathname === "/it") {
      window.location.href = "/it/";
    } else {
      window.location.href = "/";
    }
  };

  const pictureToggle = () => {
    setShowPicture(!showPicture);
  };
  const topCarousel = [{ gameNumber: 0 }, { gameNumber: 1 }, { gameNumber: 6 }, { gameNumber: 9 }, { gameNumber: 8 }, { gameNumber: 13 }, { gameNumber: 10 }, { gameNumber: 11 }];
  const bottomCarousel = [
    {
      gameName: "Robots: Energy Conflict",
      gameNumber: 6,
      gameDescription:
        "Let's go to the near future, where an artificial disaster occurred due to which cyborgs conquered the planet. The valuable primary resources of this world are multi-colored energy crystals. Players are invited to plunge into all aspects of the confrontation while playing free spins rounds. Will you find the winning combination?",
    },
    {
      gameName: "SPARKY&SHORTZ",
      gameNumber: 5,
      gameDescription:
        "You are given the opportunity to visit the planet Alpha Labori, which is hunted to the verge of extinction. Together with two robots, you can save the planet. Experienced slot machine designers crafted this game as a mix of smoothly structured mechanics, eye-popping graphics, and spinning reels with colorful lights and sounds. This is an exciting slot machine with 5 reels and 10 pay lines, which you can play to get various achievement levels!",
    },
    {
      gameName: "NUKE WORLD",
      gameNumber: 1,
      gameDescription:
        "The game takes the players to a post-apocalyptic future where they find themselves in a vortex of fierce struggle for energy resources. By spinning the slot machines reels, players will watch fictional characters who strike each other with a fantastic weapon powered by radioactive fuel. Join Captain Fox today and help him become the primary winner in the fight against opponents.",
    },
  ];

  const owlOptions1 = {
    items: 8,
    dots: true,
    dotData: true,
    margin: 5,
    autoplay: true,
    rewind: true,
    loop: true,
    autoplaySpeed: true,
    autoplayTimeout: 5500,
    responsive: {
      300: {
        items: 1.5,
      },
      560: {
        items: 3,
      },
      900: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  const owlOptions2 = {
    items: 3,
    autoplay: true,
    rewind: true,
    margin: 5,
    loop: true,
    autoplayTimeout: 6500,
    responsive: {
      300: {
        items: 1,
      },
      560: {
        items: 2,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="home-cover" style={{ backgroundImage: `url('/assets/images/cyber-bg.webp')` }}>
      <React.Fragment>
        {showSignUpPopup ? <SignUpPopup closeRegisterPopup={closeRegisterPopup} userLogged={userLogged} userRegistered={userRegistered} countryData={countryData} /> : null}
      </React.Fragment>

      <React.Fragment>{showThankYouPopup ? <ThankYouPopup closeThanksPopup={closeThanksPopup} /> : null}</React.Fragment>

      <div className="main-banner" style={window.innerWidth > 700 ? { backgroundImage: `url('/assets/images/main-banner-bg.png')` } : null}>
        <div className="container">
          {window.innerWidth < 700 ? (
            <picture>
              <source srcSet="/assets/images/main-banner-mob.webp" type="image/webp" />
              <img src="/assets/images/main-banner-mob.png" alt="Cyberslotbox" className="about-image" />
            </picture>
          ) : null}
          <div className="main-banner-content-cover">
            <div className="main-banner-content">
              <h2>Robots rule the roost</h2>
              <p>
                Immerse yourself into the brutal world of robots, full of stunning gameplay, futuristic design tricks, and let's see how you handle the mission to find your way to
                mysterious jackpots*. Would you let the robots knock you off the pace? Or would you manage to conquer this world unlike anyone else?
              </p>
              <AnchorLink href="#play-games" className="banner-play-now-button display-banner-btn">
                {window.innerWidth > 700 ? "Dare to play" : "Play now"}
              </AnchorLink>
              <button
                className="banner-play-now-button mobile-banner-btn"
                onClick={
                  !canPlay
                    ? openSignUpPopup
                    : () => {
                        navigate(`/play/0`);
                      }
                }
              >
                {window.innerWidth > 700 ? "Dare to play" : "Play now"}
              </button>
              <img
                id="joystick"
                src={showPicture ? "/assets/images/joystick.png" : "/assets/images/joystick-hovered.png"}
                className="main-banner-picture"
                onMouseOver={pictureToggle}
                onMouseOut={pictureToggle}
                alt="Cyberslotbox"
              />
            </div>
            <div className="main-banner-bottom-content">
              <h2>STAND & FIGHT ANYTIME, ANYWHERE</h2>
              <p>
                We bring users the most exciting online social games in the industry. All games listed are free to play and accessible anytime from any device, including laptops,
                PCs, or mobile devices. Why don't you get in it!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="play-games" className="home-content" style={{ minHeight: `${contentHeight}px` }}>
        <div className="featured-games">
          <div className="ad-left-col"></div>
          <div className="container">
            <div className="all-games move-top">
              <div className="all-games-block-cover">
                <div className="all-games-block">
                  <img src="/assets/images/game-12.svg" alt="Cyberslotbox" className="top-game" />
                  <React.Fragment>
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-lg" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-lg" to="/play/12">
                        Play now
                      </Link>
                    )}
                  </React.Fragment>
                </div>
              </div>
              <div className="all-games-block-right">
                <div className="all-games-block">
                  <img src="/assets/images/game-2.svg" className="vertical-games" alt="Cyberslotbox" />
                  <React.Fragment>
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-sm" to="/play/2">
                        Play now
                      </Link>
                    )}
                  </React.Fragment>
                </div>
                <div className="all-games-block">
                  <img src="/assets/images/game-3.svg" className="vertical-games" alt="Cyberslotbox" />
                  <React.Fragment>
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-sm" to="/play/3">
                        Play now
                      </Link>
                    )}
                  </React.Fragment>
                </div>
                <div className="all-games-block">
                  <img src="/assets/images/game-4.svg" className="vertical-games" alt="Cyberslotbox" />
                  <React.Fragment>
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-sm" to="/play/4">
                        Play now
                      </Link>
                    )}
                  </React.Fragment>
                </div>
                <div className="all-games-block">
                  <img src="/assets/images/game-5.svg" className="vertical-games" alt="Cyberslotbox" />
                  <React.Fragment>
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-sm" to="/play/5">
                        Play now
                      </Link>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="ad-left-col"></div>
        </div>
        <div className="featured-games-bottom">
          <div className="container">
            <div className="featured-games-bottom-list">
              <OwlCarousel options={owlOptions1}>
                {topCarousel.map(({ gameNumber }) => (
                  <div className="featured-games-bottom-item" key={gameNumber}>
                    <img src={`/assets/images/game-${gameNumber}.svg`} alt="Cyberslotbox" />
                    <React.Fragment>
                      {!canPlay ? (
                        <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                          Play now
                        </button>
                      ) : (
                        <Link className="all-games-signup all-games-signup-btn-sm" to={`/play/${gameNumber}`}>
                          Play now
                        </Link>
                      )}
                    </React.Fragment>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div className="ad-banner">
          {window.innerWidth < 700 ? (
            <iframe
              src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=toysandgames&banner=1R959EDPJQ2ZH3TE4E82&f=ifr&linkID=d721ac4f5fb410f700af6e990464df26&t=20000f83-20&tracking_id=20000f83-20"
              class="ad-1"
              title="ad-1"
              scrolling="no"
            ></iframe>
          ) : null}
        </div>

        <div className="carousel-game-icons">
          <h2>TOP ROBOTS THEMED SLOTS</h2>
          <OwlCarousel options={owlOptions2}>
            {bottomCarousel.map(({ gameName, gameNumber, gameDescription }) =>
              window.innerWidth > 700 ? (
                <div className="carousel-item" key={gameNumber}>
                  <h3>{gameName}</h3>
                  <div>
                    <div className="image-wrap">
                      {!canPlay ? (
                        <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                          Play now
                        </button>
                      ) : (
                        <Link className="all-games-signup all-games-signup-btn-sm" to={`/play/${gameNumber}`}>
                          Play now
                        </Link>
                      )}
                      <img src={`/assets/images/game-icon-${gameNumber}.webp`} alt="game" />
                    </div>
                    <div className="text-wrap">
                      <p>{gameDescription}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="carousel-item-mob" key={gameNumber}>
                  <div>
                    <img src={`/assets/images/mob-game-icon-${gameNumber}.png`} alt="game" />
                    {!canPlay ? (
                      <button className="all-games-signup all-games-signup-btn-sm" onClick={openSignUpPopup}>
                        Play now
                      </button>
                    ) : (
                      <Link className="all-games-signup all-games-signup-btn-sm" to={`/play/${gameNumber}`}>
                        Play now
                      </Link>
                    )}
                    <h3>{gameName}</h3>
                    <p>{gameDescription}</p>
                  </div>
                </div>
              )
            )}
          </OwlCarousel>
          <div className="eclipse"></div>
        </div>
        <div className="ad-banner"></div>
      </div>
    </div>
  );
}

export default Home;
