import { useEffect, useState } from "react";
import "./Disclaimer.css";

function Disclaimer() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Cyberslotbox | Disclaimer";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
    <div className="text-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover legals">
        <h1>Disclaimer</h1>
        <p>
          <strong>
            CyberSlotBox provides totally free games with no real-money winnings and social entertainment platform related information for adult audience entertainment (visitors
            under 18 years old are restricted). We do not offer any form of online gaming and remind users that success in our games does not guarantee future success at "real
            money" gaming.{" "}
          </strong>
        </p>

        <h4>Responsible gaming</h4>
        <p>
          Even though gaming is an enjoyable activity for many people, this activity may become a problem. CyberSlotBox promotes the use of "responsible gaming" and reminds users
          that playing free social games, and success playing these games, should not be considered the same as a success in "real money" games. If you, or someone you care about,
          developed a compulsive behavior towards gaming, seek professional help.
        </p>

        <h4>Affiliate Links</h4>
        <p>
          CyberSlotBox may contain links to various third-party websites. These links do not indicate that we approve of the third party website. User acknowledges and agrees that
          CyberSlotBox is not responsible for the content or other materials on such third-party websites. User acknowledges and agrees that CyberSlotBox is not liable for any loss
          or claim caused by the advertiser.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
