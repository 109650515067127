import { useEffect, useState } from "react";
import "./Privacy.css";

function Privacy() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Cyberslotbox | Privacy Policy";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "We bring users the most exciting online social games in the industry. Immerse yourself into the brutal world of robots, full of stunning gameplay and futuristic design tricks!"
      );

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
    <div className="text-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover legals">
        <h1>Privacy Policy</h1>

        <p>
          <strong>Company Information:</strong>
        </p>
        <p>
          Cyberslotbox
          <br /> 4377 Faberge Ave, Las Vegas, NV 89115-2444, USA <br />
          Email: <a href="mailto:support@cyberslotbox.com">support@cyberslotbox.com</a>
        </p>
        <p>
          Welcome to www.cyberslotbox.com (“Cyberslotbox”, “the website”). This Privacy Policy outlines our practices regarding the collection, use, and protection of your personal
          information. By accessing and using the website, you consent to these practices. If you disagree, please do not access this website.Welcome to www.cyberslotbox.com
          (“Cyberslotbox”, “the website”). This Privacy Policy outlines our practices regarding the collection, use, and protection of your personal information. By accessing and
          using the website, you consent to these practices. If you disagree, please do not access this website.
        </p>
        <p>
          <strong>Changes to Privacy Policy:</strong>
        </p>
        <p>
          <strong>Cyberslotbox reserves the right to modify this Privacy Policy at any time. Continued use of our services implies acceptance of these changes.</strong>
        </p>

        <p>
          <strong>Purpose of Information Collection:</strong>
        </p>
        <p>
          The information we collect, including your name, email address, and telephone number, is used to personalize and improve your experience on our website, provide customer
          service, and communicate with you about our services and special offers.
        </p>
        <p>
          <strong>Opt-Out Instructions:</strong>
        </p>
        <p>If you wish to opt-out of our use of your personal information, particularly for marketing purposes, please contact us using the form provided on our website.</p>
        <p>
          <strong>Compliance with Legal Obligations:</strong>
        </p>
        <p>Cyberslotbox complies with all applicable laws and regulations regarding data protection and privacy.</p>
        <p>
          <strong>Information Collection and Use:</strong>
        </p>
        <p>
          We ask for your name, email address, and telephone number when you register with us. Once you register and log into our services, you are not anonymous to us. We use the
          information to fulfill your requests for certain services and contact you about special offers and new services.
        </p>
        <p>
          <strong>Users’ Rights:</strong>
        </p>
        <p>
          You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your
          data for such purposes or if we intend to disclose your information to third parties for such purposes. You can exercise your right to prevent such processing by
          contacting us.
        </p>
        <p>
          <strong>Security Measures:</strong>
        </p>
        <p>
          Cyberslotbox employs various security measures to ensure the safety and confidentiality of your personal data. These measures include advanced encryption technologies and
          strict data access policies.
        </p>
        <p>
          <strong>Responsible Gambling:</strong>
        </p>
        <p>
          Even though gaming is an enjoyable activity for many people, it can become problematic. Cyberslotbox promotes responsible gaming and reminds users that playing free
          social games, and success in these games, does not equate to success in “real money” games. If you, or someone you care about, have developed compulsive behavior towards
          gaming, seek professional help.
        </p>
        <p>
          <strong>Third-Party Links Disclaimer:</strong>
        </p>
        <p>
          Cyberslotbox may contain links to third-party websites. These links do not indicate endorsement by Cyberslotbox. Users acknowledge that Cyberslotbox is not responsible
          for the content or other materials on such third-party websites.
        </p>
        <p>
          <strong>Contact Us:</strong>
        </p>
        <p>
          If you have any inquiries, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at{" "}
          <a href="mailto:support@cyberslotbox.com">support@cyberslotbox.com</a>.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
