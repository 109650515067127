import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './Play.css';

function Play({ is18 }) {

  let navigate = useNavigate();

  let { gameUrl } = useParams();
  const [url, setUrl] = useState();
  const [goBackLink, setGoBackLink] = useState('/');

  useEffect(() => {
    document.title = 'Сyberslotbox - Play Game';

    if (gameUrl >= 14 || isNaN(gameUrl)) {
      navigate('/page404/');
    }

    if (is18 === 'false') {
      window.location.href = `/`;
    }

    const gamesUrlList = [
        //0 iron girl
      'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=irongirl&gameId=306&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
        //1 nuke world
      'https://demo.evoplay.games/demo/fullstate/html5/evoplay/nukeworld',
        //2 coils of cash
      'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=coilsofcash&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
        //3 wild o tron 3000
      'https://netent-static.casinomodule.com/games/wildotron3000_mobile_html/game/wildotron3000_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=wildotron3000_not_mobile&gameLocation=games%2Fwildotron3000_mobile_html%2F&preBuiltGameRulesSupported=false&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-6236734838-USD&operatorId=netent',
        //4 mystery planet
      'https://demo.evoplay.games/demo/fullstate/html5/evoplay/mysteryplanet',
        //5 sparky and shortz
      'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=sparkyandshortz&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
        //6 robots energy conflict
      'https://demo.evoplay.games/demo/fullstate/html5/evoplay/robots',
        //7 beetle jewels
      'https://game-launcher-lux.isoftbet.com/255/900021?lang=en&cur=USD&mode=0&user=fun&lobbyURL=https://isoftbet.com',
        //8 spin party
      'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=spinparty&gameId=292&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
        //9 neon staxx
      'https://netent-static.casinomodule.com/games/neonstaxx_mobile_html/game/neonstaxx_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=neonstaxx_not_mobile&gameLocation=games%2Fneonstaxx_mobile_html%2F&preBuiltGameRulesSupported=true&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-8999160028-USD&operatorId=netent',
        //10 tree of light
        'https://demo.evoplay.games/demo/fullstate/html5/evoplay/treeoflight',
        //11 book of immortals
        'https://game-launcher-lux.isoftbet.com/255/908406?lang=en&cur=USD&mode=0&background=1',
        //12 star guardians
        'https://demo.evoplay.games/demo/fullstate/html5/evoplay/starguardians',
        // 13 super diamond wild
        'https://game-launcher-lux.isoftbet.com/255/909048?lang=uk&cur=USD&mode=0&background=1'
    ];
    
    if(gameUrl) {
      setUrl(gamesUrlList[gameUrl]);
    } else {
      setUrl(gamesUrlList[0]);
    }

  }, [ setUrl, gameUrl, is18, navigate]);

  const clearConsole = () => {
    console.clear();
  }
  const goBack = () => {
    navigate(-1);
  };

  return(
    <div className="text-page-body">
      <div className="content-cover">

        <div className="game-back-link" onClick={() => { clearConsole(); goBack(); }}><i className="fas fa-arrow-left"></i>Back</div>

        <div className="game-cover">
          <iframe title="game" src={ url } frameBorder="0"></iframe>
        </div>
        
      </div>
    </div>
  )
}

export default Play;