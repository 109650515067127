import React, {useEffect, useState} from 'react';
import "./Authentication.css";


export default function Authentication() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    // const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageText, setMessageText] = useState('');


    const fetchData = async (dataObj) => {
        try {
            setIsLoading(true);
            const response = await fetchFormData('/api/userspage-login.php', dataObj);
            if (typeof response.status !== "undefined" && response.status != null && response.status === "success") {
                setIsLoading(false);
                setLogin('');
                setPassword('');
                setShowMessage(true);
                setMessageText(response.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                setIsLoading(false);
                setShowMessage(true);
                setMessageText(response.message);
                setTimeout(() => {
                    setMessageText('');
                }, 1000);
            }
        } catch (error) {
            setIsLoading(false);
            setShowMessage(true);
            setMessageText('Sorry, please try again later...');
            setTimeout(() => {
                setMessageText('');
            }, 1000);
            console.error('Error:', error);
        }
    };

let isFormValid = false;
const validateForm = () => {
    let errors = {};

    if (!login) {
        errors.login = 'Login is required.';
    }

    if (!password) {
        errors.password = 'Password is required.';
    }

    setErrors(errors);
    // setIsFormValid(Object.keys(errors).length === 0);
    isFormValid = Object.keys(errors).length === 0;
};
// Submit
const handleSubmit = async () => {
    validateForm();

    if (isFormValid) {
        const data = {login: login, password: password}
        await fetchData(data);
    }
};

    function fetchFormData(url, param) {
        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
            body: new URLSearchParams(param)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <div className="overlay">

                <div className="form-block">
                    {isLoading && <p>Checking...</p>}
                    {showMessage && <div className="message-text">{messageText}</div>}
                    <form className="authentication-form">
                        <label htmlFor="name">
                            <input value={login}
                                   onChange={(e) => setLogin(e.target.value)}
                                   type="text"
                                   placeholder="Login"
                            />
                            {errors.login && <div className="auth-error_message">{errors.login}</div>}
                        </label>

                        <label htmlFor="password">
                            <input value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   type="password"
                                   placeholder="Password"
                            />
                            {errors.password && <div className="auth-error_message">{errors.password}</div>}
                        </label>
                        <span className="auth-main-btn"
                              style={{opacity: isLoading ? 0 : 1 }}
                              onClick={handleSubmit} >
                    Submit
                  </span>
                    </form>

                </div>
            </div>
        </>
    )
}