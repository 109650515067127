import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import ScrollToTop from "./ScrollToTop";
import Header from "./Header";
import Footer from "./Footer";
import Home from "../pages/Home";
import Play from "../pages/Play";
import About from "../pages/About";
import Contact from "../pages/Contact";
import OptOut from "../pages/OptOut";
import Userspage from "../pages/Userspage";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import Top10 from "../pages/Top10";
import Cookies from "../pages/Cookies";
import Disclaimer from "../pages/Disclaimer";
import Lp from "../pages/Lp";
import PageNotFound from "../pages/PageNotFound";
import PrivateRoute from "./PrivateRoute";

import "./App.css";

function App() {
  const [cookies, setCookie] = useCookies(["is18"]);
  const [countryData, setCountryData] = useState("UA");

  useEffect(() => {
    fetch(`https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`)
      .then((response) => response.json())
      .then((response) => {
        let data = {
          code: response.country_code,
          country: response.country_name,
        };
        setCountryData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  if (cookies.is18 === undefined) {
    setCookie("is18", "false", { path: "/" });
  }

  return (
    <Router>
      <div className="App">
        <Header countryData={countryData} />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home is18={cookies.is18} countryData={countryData} />} />
          <Route path="/play/:gameUrl" element={<Play is18={cookies.is18} />} />
          <Route path="/about/" element={<About />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/opt-out/" element={<OptOut />} />
          <Route path="/userspage/" element={<PrivateRoute element={<Userspage />} />} />
          <Route path="/privacy/" element={<Privacy />} />
          <Route path="/terms/" element={<Terms />} />
          <Route path="/disclaimer/" element={<Disclaimer />} />
          <Route path="/cookies/" element={<Cookies />} />
          <Route path="/it/" element={<Lp is18={cookies.is18} countryData={countryData} />} />
          <Route path="/top10/" element={<Top10 is18={cookies.is18} countryData={countryData} />} />
          <Route path="/page404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate replace to="/page404" />} />
          <Route path="/play/*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
