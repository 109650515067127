import { Link, NavLink } from "react-router-dom";

import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-cover">
        <Link to="/">
          <img width="264" height="65" src="/assets/images/logo.svg" className="footer-logo" alt="" />
        </Link>
        <h2>Disclaimer</h2>
        <p>
          CyberSlotBox provides totally free games with no real-money winnings and social gaming platform related information for adult audience entertainment (visitors under 18
          years old are restricted). We do not offer any form of online gaming and remind users that success in our games does not guarantee future success at "real money" gaming.
        </p>
        <ul className="footer-nav">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about/">About</NavLink>
          </li>
          <li>
            <NavLink to="/disclaimer/">Disclaimer</NavLink>
          </li>
          <li>
            <NavLink to="/privacy/">Privacy Policy</NavLink>
          </li>
          <li>
            <NavLink to="/terms/">Terms & Conditions</NavLink>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
